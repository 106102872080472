import { useMutation } from 'react-query';
import http from '../http.service';
import { fileEndpoint } from '../endpoints';

export const useRecommendApi = () => {
  const mutation = useMutation({
    mutationFn: async (id: string | number) => {
      return await http.post<{ success: boolean }>(fileEndpoint.RECOMMEND(id));
    },
  });
  return mutation;
};

export const useLikedApi = () => {
  const mutation = useMutation({
    mutationFn: async (id: string | number) => {
      return await http.post<{ success: boolean }>(fileEndpoint.LIKED(id));
    },
  });
  return mutation;
};

export const useCommentApi = () => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      dataQuery,
    }: {
      id: string | number;
      dataQuery: any;
    }) => {
      return await http.post<{ success: boolean }>(
        fileEndpoint.COMMENTED(id),
        dataQuery
      );
    },
  });
  return mutation;
};
