import { AxiosError, AxiosResponse } from "axios";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { queryKeys } from "../../constants/queryKeys";
import { useRootSelector } from "../../stores/reducers/root";
import {
  TAlbumDetailCache,
  TAlbumDetailResponse,
  TAlbumsResponse,
  TCheckAccessLinkResponse,
  TCreateAlbumResponse,
} from "../../types/album.type";
import { TValueSortMode } from "../../types/common.type";
import { createURLSearchParams } from "../../utils/common";
import { albumEndpoints } from "../endpoints";
import http from "../http.service";

export const useCheckAccessLinkApi = () => {
  const mutation = useMutation({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TCheckAccessLinkResponse>(
        albumEndpoints.CHECK_ACCESS_LINK,
        { link: dataQuery }
      );
    },
  });
  return mutation;
};

export const useCreateAlbumApi = () => {
  const mutation = useMutation<
    AxiosResponse<TCreateAlbumResponse, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TCreateAlbumResponse>(
        albumEndpoints.CREATE_ALBUM,
        dataQuery,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  });
  return mutation;
};

export const useEditAlbumApi = () => {
  const mutation = useMutation<
    AxiosResponse<TCreateAlbumResponse, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async ({
      id,
      dataQuery,
    }: {
      id: string | number;
      dataQuery: any;
    }) => {
      return await http.post<TCreateAlbumResponse>(
        albumEndpoints.EDIT_ALBUM(id),
        dataQuery,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  });
  return mutation;
};

export const useDeleteAlbumApi = () => {
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const url = `${albumEndpoints.DELETE_ALBUM}/${id}`;
      return await http.delete<TCreateAlbumResponse>(url);
    },
  });
  return mutation;
};

export const useGetAlbumApi = ({
  albumIdProps,
  enabled = true,
  filterParams,
  perPage,
  orderBy,
  foldersId,
}: {
  albumIdProps?: string;
  enabled?: boolean;
  perPage?: number;
  orderBy?: string;
  filterParams?: {
    is_liked?: boolean;
    is_recommended?: boolean;
    facefinder?: boolean;
  };
  foldersId?: number[];
}) => {
  const queryClient = useQueryClient();
  const { albumId } = useParams();
  const location = useLocation();
  const isCheckPath = location.pathname.split("/")[1].includes("dashboard");
  const queryKey = isCheckPath
    ? queryKeys.GET_ALBUM_DETAIL
    : queryKeys?.GET_ALBUM_DETAIL_ANO;
  const endpoint = isCheckPath
    ? albumEndpoints.GET_ALBUMS
    : albumEndpoints.CHECK_PASS_ALBUM;
  const query = useInfiniteQuery(
    [queryKey, albumId, filterParams, perPage, orderBy, foldersId],
    async ({ pageParam = 1 }) => {
      const params = {
        page: pageParam,
        per_page: perPage,
        order_by: orderBy,
        folder_ids: foldersId,
        ...filterParams,
      };
      const url = `${endpoint}/${albumIdProps || albumId}`;
      const res = await http.get<TAlbumDetailResponse>(url, { params });
      return res;
    },
    {
      enabled: enabled,
      getNextPageParam: (res) => {
        const lastPage = res?.data?.data?.files?.meta?.last_page;
        const currentPage = res?.data?.data?.files?.meta?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
      retry: 1,
      onSuccess: (data) => {
        const dataCache: TAlbumDetailCache = {
          thumbnail_url: data?.pages[0]?.data?.data?.thumbnail_url,
          name: data?.pages[0]?.data?.data?.name,
          customer_name: data?.pages[0]?.data?.data?.customer_name,
          like_count: data?.pages[0]?.data?.data?.like_count,
          comment_count: data?.pages[0]?.data?.data?.comment_count,
          file_count: data?.pages[0]?.data?.data?.file_count,
          recommend_count: data?.pages[0]?.data?.data?.recommend_count,
          id: data?.pages[0]?.data?.data?.id,
          is_downloadable: data?.pages[0]?.data?.data?.is_downloadable,
          allow_face_finder: data?.pages[0]?.data?.data?.allow_face_finder,
          folders: data?.pages[0]?.data?.data?.folders,
          comment_password: data?.pages[0]?.data?.data?.comment_password,
          frequency: data?.pages[0]?.data?.data?.frequency,
          is_commentable: data?.pages[0]?.data?.data?.is_commentable,
          password: data?.pages[0]?.data?.data?.password,
        };
        queryClient.setQueryData([`album_detail${albumId}`], dataCache);
      },
    }
  );

  return query;
};

export const useGetAlbumsApi = ({
  enabled = true,
  valueSearch,
  sortMode,
}: {
  enabled?: boolean;
  valueSearch?: string;
  sortMode?: TValueSortMode;
}) => {
  const isCreateSuccess = useRootSelector(
    (state) => state.album.isCreateSuccess
  );
  const isDeleteSuccess = useRootSelector(
    (state) => state.album.isDeleteSuccess
  );
  const query = useInfiniteQuery(
    [
      queryKeys.GET_ALBUMS,
      valueSearch,
      sortMode,
      isCreateSuccess,
      isDeleteSuccess,
    ],
    async ({ pageParam = 1 }) => {
      const params = createURLSearchParams({
        q: valueSearch,
        order_by: sortMode,
        page: pageParam,
      });
      const url = `${albumEndpoints.GET_ALBUMS}?per_page=11&${params}`;
      const res = await http.get<TAlbumsResponse>(url);
      return res;
    },
    {
      enabled: enabled,
      getNextPageParam: (res) => {
        const lastPage = res?.data?.meta?.last_page;
        const currentPage = res?.data?.meta?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  return query;
};

export const fetchAllImages = async (id: string) => {
  const params = createURLSearchParams({
    page: 1,
    per_page: 1000,
  });
  const isCheckPath = window.location.pathname
    .split("/")[1]
    .includes("dashboard");
  const endpoint = isCheckPath
    ? albumEndpoints.GET_ALBUMS
    : albumEndpoints.CHECK_PASS_ALBUM;
  const url = `${endpoint}/${id}?${params}`;
  const res = await http.get<TAlbumDetailResponse>(url);
  return res;
};
