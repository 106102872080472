export const queryKeys = {
  GET_ME: 'getMe',
  GET_ALBUMS: 'getAlbums',
  GET_ALBUM_DETAIL: 'getAlbumDetail',
  GET_ALBUM_DETAIL_ANO: 'getAlbumDetailAno',
  GET_MEMBERSHIP_PLANS: 'getMembershipPlans',
  GET_DISCOUNT: 'getDiscount',
  GET_STATIC_PAGE: 'getStaticPage',
  GET_UPGRADE: 'getUpgrade',
  GET_CALCULATE_UPGRADE: 'getCalculateUpgrade',
};
