import { Button, Modal } from "antd";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { To, useNavigate } from "react-router-dom";

export type TTypeMessage = "success" | "error" | "confirm";

export type TMessageModal = {
  type?: TTypeMessage;
  title: string;
  subTitle?: string;
  pathRedirect?: To;
  btnText?: string | React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  handleClick?: () => void;
};

const MessageModal = (props: TMessageModal) => {
  const {
    title,
    type,
    pathRedirect,
    subTitle,
    btnText,
    isVisible,
    onClose,
    handleClick,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Modal
      open={isVisible}
      onCancel={() => {
        onClose();
      }}
      footer={null}
    >
      <div className="flex justify-center items-center py-6 px-4 flex-col gap-5">
        <div
          className={`w-16 border h-16 flex justify-center items-center rounded-full ${
            type === "success"
              ? "border-secondary text-secondary"
              : type === "error"
              ? "border-primary text-primary"
              : "border-yellow-400 text-yellow-400"
          }`}
        >
          {type === "success" && <Check className="w-12 font-extrabold" />}
          {type === "error" && <X className="w-12 font-extrabold" />}
          {type === "confirm" && <span className="text-4xl">!</span>}
        </div>
        <span className="text-xl">{title}</span>
        {subTitle && <span>{subTitle}</span>}
        <div className="flex gap-2 center">
          <Button
            type={type === "success" ? "primary" : "primary"}
            danger={type === "error" || type === "confirm"}
            className={`transition-all ${type}`}
            onClick={() => {
              if (pathRedirect) navigate(pathRedirect || "/");
              !!handleClick && handleClick();
              onClose();
            }}
          >
            {btnText}
          </Button>
          {type === "confirm" && (
            <Button onClick={onClose}>{t("btnCancel")}</Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
