import { Form, Input, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Check, ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useCheckAccessLinkApi } from '../../services/apis/album.api';
import { useRootSelector } from '../../stores/reducers/root';

const apiUrl = process.env.REACT_APP_API_URL;

type TInputCheckLink = {
  name: any;
  required?: boolean;
  autoFocus?: boolean;
  label?: string;
  tooltip?: string;
  className?: string;
  onCheckLinkAccess?: (error: boolean) => void;
  defaultValue?: string;
};

const CheckLinkAccess = (props: TInputCheckLink) => {
  const {
    name,
    autoFocus,
    required,
    label,
    tooltip,
    className,
    onCheckLinkAccess,
    defaultValue,
  } = props;
  const userProfile = useRootSelector((state) => state.user.userProfile);
  const mutation = useCheckAccessLinkApi();
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<0 | 1 | 2>(0);
  const [valueInput, setValueInput] = useState<string>(undefined);
  const [error, setError] = useState<boolean>(false);

  const openPopup = (url: string) => {
    const width = 500;
    const height = 500;
    const left = (window.innerWidth - width) / 2 + window.screenX;
    const top = (window.innerHeight - height) / 2 + window.screenY;
    const windowOpen = window.open(
      url,
      'popup',
      `width=${width},height=${height},scrollbars=yes,resizable=yes,left=${left},top=${top}`
    );
    const interval = setInterval(() => {
      if (windowOpen && windowOpen.closed) {
        clearInterval(interval);
        mutation.mutate(valueInput);
      }
    }, 100);
  };

  const handleChange = () => {
    mutation.mutate(valueInput, {
      onSuccess(data) {
        if (data.data.status) {
          setIsValid(1);
          setError(false);
        } else {
          setIsValid(2);
          setError(true);
        }
      },
      onError() {
        setIsValid(2);
      },
    });
  };

  const checkValid =
    isValid === 1 ? (
      <Check className="w-5 text-secondary" />
    ) : isValid === 2 ? (
      <div className="flex gap-1 items-center">
        <Tooltip title={t('formCreateAlbum.tooltipGoGg')}>
          <div
            onClick={() =>
              openPopup(`${apiUrl}/google-drive?user_id=${userProfile?.id}`)
            }
            className="flex items-center justify-center w-6 h-6 rounded-md hover:opacity-85 bg-red-100 cursor-pointer"
          >
            <ExternalLink className="w-4 text-primary" />
          </div>
        </Tooltip>
      </div>
    ) : null;

  useEffect(() => {
    if (!!valueInput) handleChange();
    else {
      setIsValid(0);
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueInput]);

  useEffect(() => {
    onCheckLinkAccess(!error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div
      className={`relative w-full flex flex-col ${className}`}
      style={{ width: 'calc(100% - 28px)' }}
    >
      <Form.Item
        name={name}
        label={label}
        tooltip={tooltip}
        className={`mb-1 flex-1`}
        rules={[
          {
            required: required,
            message: (
              <span className="text-xs">
                {t('formCreateAlbum.requiredLinkGgDrive')}
              </span>
            ),
          },
        ]}
      >
        <Input
          autoFocus={autoFocus}
          value={valueInput}
          placeholder={t('formCreateAlbum.placeholderLinkGgDive')}
          suffix={mutation.isLoading ? <Spin size="small" /> : checkValid}
          className={`py-2 px-3 ${error && 'ant-input-status-error'}`}
          onChange={(e) => {
            setValueInput(e.target?.value);
          }}
          defaultValue={defaultValue}
        />
      </Form.Item>
      {error && (
        <span className="text-xs block text-primary">
          {t('formCreateAlbum.checkAccessLink')}
        </span>
      )}
    </div>
  );
};

export default CheckLinkAccess;
