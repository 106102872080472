import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Album from '../../pages/Album';
import { useGetAlbumApi } from '../../services/apis/album.api';
import { setPassAlbum } from '../../stores/reducers/auth.reducer';
import LoadingPage from '../LoadingPage/LoadingPage';

const ConfirmPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const password = Form.useWatch('password', form);
  const { refetch, isLoading, isError, data } = useGetAlbumApi({});
  const [error, setError] = useState<string>('');

  const onFinish = (values: { password: string }) => {
    dispatch(setPassAlbum(values?.password));
    refetch();
  };

  useEffect(() => {
    if (error !== '') setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  useEffect(() => {
    if (isError && !!password) setError(t('formConfimPassAlbum.confirmFail'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (isLoading && !password) return <LoadingPage />;

  return (
    <>
      {data?.pages[0]?.status === 200 ? (
        <Album />
      ) : (
        <>
          <div className="relative py-4 flex flex-col items-center sm:gap-3 gap-2 md:max-w-96 m-auto">
            <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
              {t('formConfimPassAlbum.title')}
            </div>
            <div className="text-slate-500 flex gap-1 text-sm">
              {t('formConfimPassAlbum.subTitle')}
            </div>
            {error && (
              <div className="text-sm text-primary mr-auto">{error}</div>
            )}
            <Form
              form={form}
              name="confirm-pass-form"
              onFinish={onFinish}
              className="w-full"
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: (
                      <span className="text-xs mb-1 block">
                        {t('formRegister.requiredPassword')}
                      </span>
                    ),
                  },
                  {
                    min: 6,
                    message: (
                      <span className="text-xs mb-1 block">
                        {t('formRegister.minPassword')}
                      </span>
                    ),
                  },
                ]}
              >
                <Input
                  type="password"
                  autoFocus
                  placeholder={t('formRegister.password')}
                  className="py-2 px-3"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  className="success transition-all mt-4 font-medium"
                  size="large"
                  loading={isLoading && !!password}
                  disabled={isLoading && !!password}
                >
                  {t('formConfimPassAlbum.changePass')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmPassword;
