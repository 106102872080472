export const path = {
  home: '/',
  login: '/login',
  register: '/register',
  logout: '/logout',
  profile: '/dashboard/profile',
  setting: '/dashboard/setting',
  dashboard: '/dashboard',
  recovery: '/recovery',
  reset: '/reset-password/:token',
  albumUser: '/dashboard/albums/:albumId',
  album: '/albums/:albumId',
  servicepackages: '/service-packages',
  packageManagement: '/dashboard/package-management',
  staticpage: '/page/:path',
};
